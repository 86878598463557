import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upload, initializeLabeling, setImages, setIsExistingDataset } from 'src/redux/actions/dataset';
import { Box, Text, Button, Progress, VStack, HStack, Center } from 'native-base';
import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';

const ImageUploadPage = ({ navigation }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();

  const datasetId = useSelector((state) => state.dataset.datasetID);
  const grainId = useSelector((state) => state.grainType.grainId);
  const uploadProgress = useSelector((state) => state.dataset.uploadProgress);
  const uploadCompleted = useSelector((state) => state.dataset.uploadCompleted);

  useEffect(() => {
    if (uploadCompleted) {
      setIsUploading(false);
      setSelectedFiles([]);
      navigation.navigate('DatasetListingPage', { refresh: true });
    }
  }, [uploadCompleted, navigation]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).filter(file => {
      if (!file.type.startsWith('image/')) {
        alert(`File ${file.name} is not an image.`);
        return false;
      }
      return true;
    });
    setSelectedFiles(files);
    
    if (uploadCompleted) {
      dispatch({ type: 'RESET_UPLOAD_STATE' });
    }
  };

  const handleUpload = useCallback(() => {
    if (selectedFiles.length > 0 && grainId) {
      setIsUploading(true);
      dispatch(upload.request({ files: selectedFiles, grainId, datasetId }));
  
      const request = {
        imageList: selectedFiles.map(img => ({
          imagePath: `ml-images/${grainId}/${datasetId}/${img.name}`,
          label: img.label || "",
        })),
      };
      dispatch(setImages(request.imageList));
    }
  }, [selectedFiles, dispatch, grainId, datasetId]);

  const handleCreateNewDataset = () => {
    if (grainId) {
      dispatch(setIsExistingDataset(false));
      dispatch(initializeLabeling.request({ grainId }));
      navigation.navigate('ImageUploadPage');
    } else {
      alert('Please select a grain type before creating a new dataset.');
    }
  };

  return (
    <Center flex={1}>
      <Box width="90%" maxWidth="400px" borderWidth={1} borderRadius="md" padding={4} shadow={2}>
        <VStack space={4}>
          <HStack alignItems="center" space={2}>
            <Text fontSize="xl" fontWeight="bold">Upload Multiple Images</Text>
          </HStack>
          
          <GrainTypeDropdown />

          <Button onPress={handleCreateNewDataset} mt={4} isDisabled={!grainId}>Create New Dataset</Button>

          {datasetId && (
            <Text fontSize="sm" color="gray.600" mb={4}>
              Current Dataset ID: {datasetId}
            </Text>
          )}

          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-button"
            multiple
            type="file"
            onChange={handleFileChange}
            disabled={isUploading || !datasetId || !grainId}
          />
          <label htmlFor="upload-button">
            <Button
              colorScheme="primary"
              variant="outline"
              width="full"
              onPress={() => (datasetId && grainId) ? document.getElementById('upload-button').click() : null}
              isDisabled={isUploading || !datasetId || !grainId}
            >
              Choose Images
            </Button>
          </label>

          {selectedFiles.length > 0 && (
            <Text fontSize="sm">
              {selectedFiles.length} file(s) selected.
            </Text>
          )}

          {isUploading && uploadProgress < 100 && (
            <VStack space={2}>
              <Text fontSize="sm">Upload Progress: {uploadProgress}%</Text>
              <Progress value={uploadProgress} size="sm" colorScheme="secondary" />
            </VStack>
          )}
          <Button
            colorScheme="primary"
            onPress={handleUpload}
            isDisabled={selectedFiles.length === 0 || isUploading || !datasetId || !grainId}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default ImageUploadPage;