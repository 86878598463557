import React from 'react';
import { Text, View, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import DisplayIndiGrainResultsTable from '../../components/DisplayIndiGrainResultsTable';
import { JobStatus } from '../../models';
import { IStore } from '../../redux/reducers';
import { containerStyle } from 'src/styles/containerStyle';
import { Box, Center } from 'native-base';
import { getText } from 'src/utils/i18n';
import { useIsFocused } from '@react-navigation/native';

export default function IndiGrainResultsPage({ navigation }) {
    const job = useSelector((store: IStore) => store.job.jobStatus.job);
    const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);

    const productType = useSelector((store: IStore) => store.job.jobStatus.job?.productName)
        || "GRAIN_ANALYSIS";

    const isFocused = useIsFocused();

    if (!isFocused) {
        return <Box variant={"main"} style={containerStyle.mainContainer}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ padding: 12, textAlign: 'center' }}>Loading...</Text>
            </View>
        </Box>
    }

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            {(jobStatus == JobStatus.JOB_COMPLETED
                && productType !== "L10_GRAIN_ANALYSIS"
                && productType !== "MANUAL_GRAIN_ANALYSIS"
                && job) ? (<>
                    <ScrollView>
                        <DisplayIndiGrainResultsTable
                            jobId={job.jobId}
                            numItemsPerPage={10}
                            productType={productType}
                            csvFileUrl={job.results?.indi_grain_csv} />
                    </ScrollView>
                </>) : ((productType !== "L10_GRAIN_ANALYSIS"
                    && productType !== "MANUAL_GRAIN_ANALYSIS"
                ) ? (<>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ padding: 12, textAlign: 'center' }}>{getText('selectImageTabNotice')}</Text>
                    </View>
                </>) : (
                    <>
                        <View
                            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Text
                                style={{ padding: 12, textAlign: 'center' }}>{getText('noResultsFound')} 
                            </Text>
                        </View>
                    </>
                )
            )}
        </Box>
    );

}